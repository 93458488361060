import React from 'react'

// Vendor
import { useTranslation } from 'react-i18next'
import { useTable } from 'react-table'
import isFunction from 'lodash/isFunction'
import isString from 'lodash/isString'

// Reactor UI
import { Flex, Box } from 'reactor-ui'
import Card from 'reactor-ui/components/Card'
import Icon from 'reactor-ui/components/Icon'
import Button from 'reactor-ui/components/Button'
import Alert from 'reactor-ui/components/Alert'
import Tooltip from 'reactor-ui/components/Tooltip'
import { Checkbox } from 'reactor-ui/components/Checkbox'
import Loader from 'reactor-vera/apps/ui/components/Loader'
import * as changeCase from 'reactor-ui/util/text'
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
} from 'reactor-ui/components/Table'

// Reactor
import useModelListReactorQuery from 'reactor/hooks/useModelListReactorQuery'
import useReactorQuery from 'reactor/hooks/useReactorQuery'
import { Link } from 'reactor-vera/router'
import Layout from 'reactor-vera/apps/blocks/components/Layout'

import renderers from 'reactor-vera/apps/model/components/ModelRenderers'
import { useParams } from 'reactor-vera/router'

import ActionGroup, { ActionGroupMenu, MultipleActionGroupMenu } from './ActionGroup'
import Pagination from './Pagination'
import FilterInput from './Filters'


const GraphTable = ({
  modelName,
  filterKey,
  query,
  views,
  stats,

  primaryFilters,

  actionCtx,

  detailLink,

  elements,

  headerText,
  alignLast = true,

  showModelActions = true,
  showEntityActions = true,
  defaultModelActionContext = 'Model',
  defaultModelActionKind = 'CREATE',
  defaultEntityActionContext = 'Entity',

  isModelActionIconOnly = false,

  filters,

  exporters,

  sx,

  displayAs = {
    'mode': 'table'
  }
}) => {
  const [activeView, setActiveView] = React.useState(views?.[0])

  const modelListQuery = useModelListReactorQuery({
    modelName,
    filterKey,
    query: activeView?.query || query,
    view: activeView || query?.view,
    stats,
    primaryFilters
  })

  const [result, graphKey, filterInstance, queryObj, selection] = modelListQuery

  const graphResult = result?.graph?.[graphKey]

  const Wrapper = displayAs.mode === 'table' ? Card : Box
  const HeaderWrapper = displayAs.mode === 'table' ? Box : Card

  // const { count, items, columns, nextCursor, page, prevCursor } = result.graph[graphKey]

  return (
    <Wrapper sx={{
      width: '100%',
      maxWidth: '100%',
      ...sx
    }}>
      <HeaderWrapper>
        <GraphTableHeader
          headerText={headerText}
          modelName={modelName}
          actionCtx={actionCtx}
          views={views}
          activeView={activeView}
          setActiveView={setActiveView}
          elements={elements}

          showModelActions={showModelActions}
          defaultModelActionContext={defaultModelActionContext}
          defaultModelActionKind={defaultModelActionKind}
          exporters={exporters}
          isModelActionIconOnly={isModelActionIconOnly}

          modelListQuery={modelListQuery}
        />
      </HeaderWrapper>

      <GraphError result={result} />

      <OutTableElements elements={elements} result={result} kind='beforeTable' />

      <HeaderFilters filters={filters} filterInstance={filterInstance} />

      <HeaderPrimaryFilters graphResult={graphResult} filterInstance={filterInstance} />

      <Content
        modelListQuery={modelListQuery}
        modelName={modelName}
        displayAs={displayAs}

        alignLast={alignLast}
        detailLink={detailLink}
        elements={elements}

        showEntityActions={showEntityActions}
        defaultEntityActionContext={defaultEntityActionContext}
        actionCtx={actionCtx}
      />

      <OutTableElements elements={elements} result={result} kind='afterTable' />

      <GraphTableFooter graphResult={graphResult} filterInstance={filterInstance} />
    </Wrapper>
  )
}

const parseColumnKeys = (colNames, schemas, baseSchema) => {
  const colNameToUse = colNames[0]
  const colSchema = schemas[colNameToUse] || baseSchema[colNameToUse]
  if (!colSchema) return null
  const colSchemaName = colSchema.valueType == 'Object' ? colSchema.schemaType : colNameToUse
  const schema = schemas[colSchemaName]
  if (colNames.length === 1) return schema
  if (!schema) return null
  return parseColumnKeys(colNames.slice(1), schema, colSchema)
}

const Content = ({
  modelListQuery,
  modelName,
  displayAs,

  alignLast,
  detailLink,
  elements,

  showEntityActions,
  defaultEntityActionContext,
  actionCtx,
}) => {
  const [result, graphKey, filterInstance, queryObj, selection] = modelListQuery
  const graphResult = result?.graph?.[graphKey]
  if (!graphResult) return null
  if (displayAs.mode === 'table') {
    return (
      <GraphTableContent
        modelListQuery={modelListQuery}
        modelName={modelName}
        columnNames={graphResult.columns}
        items={graphResult.items}
        schemas={result.schema}
        alignLast={alignLast}
        detailLink={detailLink}
        elements={elements}
        showEntityActions={showEntityActions}
        filterInstance={filterInstance}
        actionCtx={actionCtx}
        selection={selection}
        defaultEntityActionContext={defaultEntityActionContext}
      />
    )
  } else if (displayAs.mode === 'grid') {
    return (
      <GridTableContent
        items={graphResult.items}
        columnNames={graphResult.columns}
        selection={selection}
        schemas={result.schema}
        elements={elements}
        modelName={modelName}
        filterInstance={filterInstance}
        detailLink={detailLink}
        showEntityActions={showEntityActions}
        actionCtx={actionCtx}
        displayAs={displayAs}
        defaultEntityActionContext={defaultEntityActionContext}
      />
    )
  } else {
    return null
  }
}

const GraphTableContent = ({
  modelName,
  columnNames,
  items,
  schemas,
  actionCtx,

  detailLink,

  alignLast,

  elements,

  showEntityActions,

  filterInstance,

  selection,

  defaultEntityActionContext
}) => {
  const { t } = useTranslation()

  const columnCount = columnNames.length

  const tableColumns = React.useMemo(() => {
    const result = columnNames.map((colKey, dx) => {
      // console.log('--', colKey, schemas)
      let colName
      let colSchema
      let header
      let colKeys
      let accessor
      let keys
      let id

      const modelSchema = schemas[changeCase.camel(modelName)]

      if (colKey.includes('.')) {
        colKeys = colKey.split('.')
        colSchema = parseColumnKeys(colKeys, schemas, modelSchema)
        colName = colKey
        keys = colKeys.slice(1)
        // colSchema = schemas[changeCase.camel(modelName)][colName]
        // header = colKeys[colKeys.length - 1]
        header = colKeys[0]
        // id = colKeys[colKeys.length - 1]
        id = colKey
        accessor = colKeys[0]
      } else {
        colName = colKey
        colSchema = modelSchema[colName]
        header = colKey
        accessor = colKey
        id = colKey
      }
      return ({
        id: id,
        renderer: elements?.columns?.[colKey],
        Header: header,
        accessor: accessor,
        keys: keys,
        colKey: colKey,
        colKeys: colKeys,
        schema: colSchema,
        filterInstance,
        alignLast,
        link: dx === 0 && detailLink ? isFunction(detailLink) ? detailLink : (row) => `${detailLink}/${row.id}` : null
      })
    })
    if (elements?.entityActions) result.push({
      Header: elements.entityActions.header,
      id: '@customEntityActions',
      renderer: (cell) => <TableElements elements={elements.entityActions.items} entity={cell.row} />,
      alignLast: true,
    })
    if (showEntityActions) result.push({
      Header: '',
      id: '@entityActions',
      renderer: (cell) => <ActionGroupMenu name={modelName} contexts={[defaultEntityActionContext]} ctx={{ id: cell.row.id, ...actionCtx?.entity }} />,
      alignLast: true,
    })

    if (selection.isActive) result.unshift({
      Header: (
        <Checkbox isChecked={selection.checkIds(items?.map(i => i.id))} onChange={(e) => {
          if (e.target.checked) selection.actions.selectItems(items?.map(i => i.id))
          else selection.actions.unselectItems(items?.map(i => i.id))
        }} />
      ),
      id: '@selection',
      renderer: (cell) => (
        <Checkbox isChecked={selection.selection?.[cell.row.id]} onChange={(e) => {
          if (e.target.checked) selection.actions.selectItem(cell.row.id)
          else selection.actions.unselectItem(cell.row.id)
        }} />
      )
      // id: '@entityActions',
      // renderer: (cell) => <ActionGroupMenu name={modelName} contexts={['Entity']} ctx={{ id: cell.row.id, ...actionCtx?.entity }} />,
      // alignLast: true,
    })
    return result
  }, [columnNames, selection.isActive, items])

  const tableRows = items

  const tableInstance = useTable({
    columns: tableColumns,
    data: tableRows,
    getRowId: (row) => row.id
  })

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance

  return (
    <Box mx={-4} sx={{
      overflow: 'auto',
      display: 'block',
      maxWidth: '100%',
      mt: 2,
      mb: 3
    }}>
      <Table {...getTableProps()} variant="striped" colorScheme="light" size='sm'>
        <Thead sx={{

        }}>
          {headerGroups.map(headerGroup => {
            return (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, dx) => {
                  const { key, ...headerProps } = column.getHeaderProps()
                  return (
                    <GraphTableColumnHeader filterInstance={filterInstance} isLast={dx === columnCount} key={key} headerProps={headerProps} column={column} />
                  )
                })}
              </Tr>
            )
          })}
        </Thead>
        <Tbody {...getTableBodyProps()} sx={{

        }}>
          {rows.map((row, dx) => {
            prepareRow(row)
            return (
              <Tr {...row.getRowProps()}>
                {row.cells.map((cell, cellDx) => {
                  const { key, cellProps } = cell.getCellProps()
                  return <GraphTableCell isLast={cellDx === columnCount} key={key} cellProps={cellProps} cell={cell} />
                })}
              </Tr>
            )
          })}
          {rows.length === 0 && (
            <tr>
              <Box as='td' colSpan={columnCount} sx={{
                textAlign: 'center',
                bg: 'var(--chakra-colors-light) !important',
                height: '50px'
              }}>
                {t('text.data_empty')}
              </Box>
            </tr>
          )}
        </Tbody>
      </Table>

    </Box>
  )

}

const GridTableContent = ({
  columnNames,
  items,
  selection,
  schemas,
  elements,
  modelName,
  filterInstance,
  detailLink,
  showEntityActions,
  actionCtx,
  displayAs,
  defaultEntityActionContext
}) => {
  const tableColumns = React.useMemo(() => {
    const result = columnNames.map((colKey, dx) => {
      // console.log('--', colKey, schemas)
      let colName
      let colSchema
      let header
      let colKeys
      let accessor
      let keys
      let id

      const modelSchema = schemas[changeCase.camel(modelName)]

      if (colKey.includes('.')) {
        colKeys = colKey.split('.')
        colSchema = parseColumnKeys(colKeys, schemas, modelSchema)
        colName = colKey
        keys = colKeys.slice(1)
        // colSchema = schemas[changeCase.camel(modelName)][colName]
        // header = colKeys[colKeys.length - 1]
        header = colKeys[0]
        // id = colKeys[colKeys.length - 1]
        id = colKey
        accessor = colKeys[0]
      } else {
        colName = colKey
        colSchema = modelSchema[colName]
        header = colKey
        accessor = colKey
        id = colKey
      }
      return ({
        id: id,
        renderer: elements?.columns?.[colKey],
        Header: header,
        accessor: accessor,
        keys: keys,
        colKeys: colKeys,
        colKey: colKey,
        schema: colSchema,
        filterInstance,
        link: dx === 0 && detailLink ? isFunction(detailLink) ? detailLink : (row) => `${detailLink}/${row.id}` : null
      })
    })
    if (elements?.entityActions) result.push({
      Header: elements.entityActions.header,
      id: '@customEntityActions',
      renderer: (cell) => <TableElements elements={elements.entityActions.items} entity={cell.row} />,
    })
    if (showEntityActions) result.push({
      Header: '',
      id: '@entityActions',
      renderer: (cell) => <ActionGroupMenu name={modelName} contexts={[defaultEntityActionContext]} ctx={{ id: cell.row.id, ...actionCtx?.entity }} />,
    })

    if (selection.isActive) result.unshift({
      Header: (
        <Checkbox isChecked={selection.checkIds(items?.map(i => i.id))} onChange={(e) => {
          if (e.target.checked) selection.actions.selectItems(items?.map(i => i.id))
          else selection.actions.unselectItems(items?.map(i => i.id))
        }} />
      ),
      id: '@selection',
      renderer: (cell) => (
        <Checkbox isChecked={selection.selection?.[cell.row.id]} onChange={(e) => {
          if (e.target.checked) selection.actions.selectItem(cell.row.id)
          else selection.actions.unselectItem(cell.row.id)
        }} />
      )
      // id: '@entityActions',
      // renderer: (cell) => <ActionGroupMenu name={modelName} contexts={['Entity']} ctx={{ id: cell.row.id, ...actionCtx?.entity }} />,
      // alignLast: true,
    })
    return result
  }, [columnNames, selection.isActive, items])

  const cards = items.map((item, itemDx) => {
    if (displayAs.cardRenderer) return displayAs.cardRenderer(item, itemDx)
    const imageUrl = displayAs.imageUrl ? isFunction(displayAs.imageUrl) ? displayAs.imageUrl(item) : displayAs.imageUrl : null
    const image = imageUrl && (
      <Box sx={{
        mx: -4,
        mt: -3,
        mb: 2,
        height: 150,
        overflow: 'hidden',
      }}>
        <Box as='img' src={imageUrl} sx={{
          width: '100%',
          height: '100%',
          // display: 'block',
          objectFit: 'cover'
        }} />
      </Box>
    )
    const cardName = (
      <Box sx={{
        fontWeight: 'semibold'
      }}>
        {item[columnNames[0]]}
      </Box>
    )
    const cardMenu = <ActionGroupMenu name={modelName} contexts={[defaultEntityActionContext]} ctx={{ id: item.id, ...actionCtx?.entity }} />
    const cardSubHeader = (
      <Box>
        {item[columnNames[1]]}
      </Box>
    )
    const detailUrl = detailLink && isFunction(detailLink) ? detailLink(item) : `${detailLink}/${item.id}`

    let content
    if (detailUrl) {
      content = (
        <Card>
          <Link
            to={detailUrl}
          >
            {image}
          </Link>
          <Layout.Row>
            <Link
              to={detailUrl}
            >
              {cardName}
            </Link>
            {cardMenu}
          </Layout.Row>
          <Link
            to={detailUrl}
          >
            {cardSubHeader}
          </Link>
        </Card>
      )
    } else {
      content = (
        <Card>
          {image}
          <Layout.Row>
            {cardName}
            {cardMenu}
          </Layout.Row>
          {cardSubHeader}
        </Card>
      )
    }

    return (
      <Box
        key={item.id}
        sx={{
          minWidth: ['45%', null, null, null, '18%'],
          flex: '1 1 0',
          mx: 2,
          mb: 2,
        }}
      >
        {content}
      </Box>
    )
  })

  return (
    <>
      <Flex sx={{
        mx: -2,
        flexWrap: 'wrap',
        my: 3,
        justifyContent: 'space-between'
      }}>

        {displayAs?.beforeContent}
        {cards}
        {displayAs?.afterContent}

        {items.length > 0 && (
          <>
            <Box sx={{
              minWidth: ['45%', null, null, null, '18%'],
              flex: '1 1 0',
              // px: 4,
              // py: 3,
              mx: 2,
              mb: 2
            }} />
            <Box sx={{
              minWidth: ['45%', null, null, null, '18%'],
              flex: '1 1 0',
              // px: 4,
              // py: 3,
              mx: 2,
              mb: 2
            }} />
            <Box sx={{
              minWidth: ['45%', null, null, null, '18%'],
              flex: '1 1 0',
              // px: 4,
              // py: 3,
              mx: 2,
              mb: 2
            }} />
            <Box sx={{
              minWidth: ['45%', null, null, null, '18%'],
              flex: '1 1 0',
              // px: 4,
              // py: 3,
              mx: 2,
              mb: 2
            }} />
          </>
        )}
      </Flex>
    </>
  )
}

const GraphTableColumnHeader = ({
  headerProps,
  column,
  isLast,
  filterInstance
}) => {
  // return null
  const { t } = useTranslation()
  const { locationFilters } = filterInstance
  const [filterOpen, setFilterOpen] = React.useState(!!locationFilters[column.colKey] || false)
  let header
  if (column.Header) {
    if (column.colKeys?.length > 1) {
      let mainHeader, subHeader

      if (isString(column.Header)) {
        mainHeader = t(`field.${changeCase.snake(column.Header)}`, changeCase.snake(column.Header))
      } else {
        mainHeader = column.Header
      }

      const lastKey = column.colKeys[column.colKeys.length - 1]
      subHeader = t(`field.${changeCase.snake(lastKey)}`, changeCase.snake(lastKey))

      header = (
        <Box>
          <Box sx={{
            fontSize: 'xs',
            color: 'dark.300'
          }}>
            {mainHeader}
          </Box>

          <Box>
            {subHeader}
          </Box>
        </Box>
      )
    } else {
      if (isString(column.Header)) {
        header = t(`field.${changeCase.snake(column.Header)}`, changeCase.snake(column.Header))
      } else {
        header = column.Header
      }
    }
  }
  const filterType = column.schema?.filterType
  let content
  let maxWidth
  if (column.schema?.schemaType === 'Numeric') maxWidth = '120px'
  // console.log(column.Header, locationFilters, filterOpen, column.schema)

  const { sortBy, sortDirection } = filterInstance.getOrder()
  const columnSortDirection = sortBy == column.colKey ? sortDirection : null
  let nextSortDirection = 'asc'
  if (columnSortDirection == 'asc') nextSortDirection = 'desc'
  else if (columnSortDirection == 'desc') nextSortDirection = null
  let handleSort = () => filterInstance.handleSort({ sortBy: column.colKey, sortDirection: nextSortDirection })
  // if (filterInstance.locationFilters._pgn) handleSort = null

  if (filterType) {
    content = (
      <>
        <Flex sx={{
          alignItems: 'center',
          // justifyContent: 'space-between'
        }}>
          <Box as='a' onClick={handleSort}>
            {header}
          </Box>
          {columnSortDirection && <Icon name={`arrow-${columnSortDirection == 'asc' ? 'up' : 'down'}`} style={{ fontSize: 10 }} />}
          <Box sx={{
            ml: 2
          }}>
            <a onClick={() => setFilterOpen(v => !v)}>
              <Icon name='search' size={4} color='brand.300' />
            </a>
          </Box>
        </Flex>
        {filterOpen && <ColumnFilter column={column} label={header} />}
      </>
    )
  } else {
    content = header
  }

  return (
    <Th {...headerProps} isNumeric={column.alignLast && isLast} sx={{
      maxWidth
    }}>
      {content}
    </Th>
  )
}

const ColumnFilter = ({
  column,
  label
}) => {
  const filterType = column.schema?.filterType
  const schema = column.schema
  const colKey = column.colKey
  const filterInstance = column.filterInstance

  return (
    <Box mt={2}>
      <FilterInput label={label} target={colKey} filterInstance={filterInstance} kind={filterType} schema={schema} />
    </Box>
  )
}

const GraphTableCell = ({
  cellProps,
  cell,
  isLast
}) => {
  let content, alignColumnToRight
  const column = cell.column
  alignColumnToRight = column.alignLast && isLast

  if (column.renderer) {
    content = cell.column.renderer(cell)
  } else {
    // console.log('--', column)
    const columnSchema = column.schema

    let valueType, schemaType
    if (columnSchema) {
      valueType = columnSchema.valueType
      schemaType = columnSchema.schemaType
    } else {
      valueType = 'Scalar'
      schemaType = 'Text'
    }

    let value = cell.value
    if (column.keys) {
      column.keys.forEach(key => {
        value = value?.[key]
      })
    }
    // console.log(column.Header, column.keys, columnSchema, value, cell.value)

    // return <Td {...cellProps}/>

    let renderer
    if (column.renderer) {
      renderer = column.renderer
    } else if (valueType === 'Scalar') {
      renderer = renderers[schemaType]
    } else if (valueType === 'Object') {
      renderer = renderers.Object
    }

    // content = renderer ? renderer(value, cell) : cell.render('Cell')
    content = renderer ? renderer(value, cell) : value
  }

  const { link } = column

  if (link) {
    content = (
      <Link sx={{
        color: 'primary',
        textDecoration: 'underline'
      }} to={link(cell.row)}>
        {content}
      </Link>
    )
  }

  return (
    <Td {...cellProps} isNumeric={alignColumnToRight}>
      {content}
    </Td>
  )
}

const TableElements = ({
  elements,
  ...props
}) => {
  if (!elements) return null

  return (
    <Flex sx={{
      alignItems: 'center'
    }}>
      {elements?.map((element, dx) => {
        return isFunction(element) ? element({ ...props, dx }) : element
      })}
    </Flex>
  )
}

import URI from 'urijs'

const DownloadLink = ({
  queryObj,
  graphKey
}) => {
  const params = useParams()
  if (!params || !params.rcTenantId) return null
  const url = new URI('/__reactor/api/reactor_query_export')
  const query = {
    [graphKey]: {
      ...queryObj[graphKey],
      limit: 20000
    }
  }

  url.setQuery({
    query: JSON.stringify(query),
    // '@rcTenantId': 1,
    '@rcTenantId': params.rcTenantId,
    'X-REACTOR-CHANNEL': 'Reactor'
  })
  return (
    <Box as='a' sx={{
      color: 'primary',
      textDecoration: 'underline'
    }}
      // download
      target='_blank'
      href={url.toString()}>
      <Button iconName='file-spreadsheet' size='xs' />
    </Box>
  )
}

import Portal from 'reactor-ui/components/Portal'
import { Menu, MenuButton, MenuList, MenuItem, useDisclosure } from 'reactor-ui/components/menu'

const Exporters = ({
  exporters
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [activeExporter, setActiveExporter] = React.useState(null)
  return (
    <>
      <Menu
        isLazy
        onOpen={onOpen}
        onClose={onClose}
        // isOpen={isOpen}
        // onClose={() => {
        //   onClose()
        //   // setContent(null)
        // }}
        closeOnSelect={false}
        closeOnBlur={!activeExporter}
      >
        {({ isOpen }) => (
          <>
            <MenuButton as={Button} iconName='export' size='xs' />
            <Portal>
              <MenuList>
                <ExporterContent exporters={exporters} activeExporter={activeExporter} setActiveExporter={setActiveExporter} />
              </MenuList>
            </Portal>
          </>
        )}
      </Menu>
    </>
  )
}

import QueryLoading from 'reactor-vera/apps/ui/components/QueryLoading'

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "reactor-ui/components/Modal"
import { set } from 'lodash'

const ExporterContent = ({
  exporters,
  activeExporter,
  setActiveExporter
}) => {
  const { t } = useTranslation()
  const result = useReactorQuery({
    reactorExporters: {
      exporters
    }
  })

  return (
    <Box>
      <QueryLoading result={result}>
        {result.graph?.reactorExporters.exporters.map((e, dx) => {
          return (
            <MenuItem sx={{
              textTransform: 'capitalize'
            }} onClick={() => setActiveExporter(e)} key={dx}>
              {t(`label.${e.label}`)}
            </MenuItem>
          )
        })}

        <Modal
          autoFocus={false}
          closeOnOverlayClick={false}
          isOpen={!!activeExporter}
          onClose={() => {
            setActiveExporter(null)
          }}
          size={'xl'}
          trapFocus={false}
        >
          <ModalOverlay />
          {activeExporter && <ExporterDetail exporter={activeExporter} />}
        </Modal>
      </QueryLoading>
    </Box>
  )
}

import ReactorServerAction from 'reactor-vera/apps/data/components/ReactorServerAction'

const ExporterDetail = ({
  exporter
}) => {
  const { t } = useTranslation()

  const result = useReactorQuery({
    rcReactorExportList: {
      filters: {
        exporter: exporter.name,
        exportKey: exporter.key,
      },
      itemFields: [
        'exporter', 'fileKind', 'datetimeInitiated', 'status', 'file'
      ],
      limit: 100
    }
  })

  return (
    <ModalContent>
      <ModalHeader textTransform='capitalize'>{t(`label.${exporter.label}`)}</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <QueryLoading result={result}>
          <ReactorServerAction
            identifier='RcUniversal.CreateReactorExport'
            initialValues={{
              identifier: exporter.identifier,
              params: exporter.params,
            }}
          />
          {result.graph?.rcReactorExportList.count === 0 ? (
            'Kayıt yok'
          ) : (
            <Box>
              {result.graph?.rcReactorExportList.items.map(item => {
                return (
                  <Flex key={item.id} sx={{
                    my: 2,
                    alignItems: 'center'
                  }}>
                    <Icon name='file' color='brand.500' wrapperSx={{
                      flex: '0 0 200px'
                    }}>
                      {renderers.DateTime(item.datetimeInitiated)}
                    </Icon>

                    <Box sx={{
                      ml: 4
                    }}>
                      {renderers.Option(item.status)}
                    </Box>

                    {item.status.value === 'DONE' && <Box sx={{
                      mx: 4
                    }}>
                      <Box as='a' href={item.file.url}>
                        <Icon name='square-download' color='brand'>
                          İndir
                        </Icon>
                      </Box>
                    </Box>}
                  </Flex>
                )
              })}
            </Box>
          )}
        </QueryLoading>
      </ModalBody>
      <ModalFooter />
    </ModalContent>
  )
}

const GraphViews = ({
  views: staticViews = [],
  activeView: selectedActiveView,
  setActiveView,
  serverViews = [],
  serverActiveView,
}) => {
  const { t } = useTranslation()
  let activeView
  if (selectedActiveView) {
    activeView = selectedActiveView
  } else if (serverActiveView) {
    activeView = serverActiveView
  }

  const views = [
    ...staticViews,
    ...serverViews
  ]

  const { isOpen, onOpen, onClose, onToggle } = useDisclosure()
  return (
    <>
      <Menu
        // isLazy
        onOpen={onOpen}
        onClose={onClose}
        isOpen={isOpen}
      // onClose={() => {
      //   onClose()
      //   // setContent(null)
      // }}
      // closeOnSelect={false}
      // closeOnBlur={!content}
      >
        {({ isOpen }) => (
          <>
            <MenuButton>
              <Button onClick={onToggle} size='xs' sx={{
                // fontSize: 'sm',
                textTransform: 'uppercase',
                fontWeight: 'semibold',
                color: 'brand.500'
              }}>
                <Flex alignItems='center'>
                  {t(activeView.label)}
                  <Icon name='chevron-down' size={3} />
                </Flex>
              </Button>
            </MenuButton>
            {/* {isOpen && rendered} */}
            <Portal>
              <MenuList>
                {views.map((view, dx) => {
                  return (
                    <MenuItem sx={{
                      textTransform: 'capitalize'
                    }} key={dx} onClick={() => setActiveView(view)}>
                      {t(view.label)}
                    </MenuItem>
                  )
                })}
              </MenuList>
            </Portal>
          </>
        )}
      </Menu>
    </>
  )
}
{/* <GraphViews views={views} activeView={activeView} setActiveView={setActiveView}/> */ }

const GraphTableHeader = ({
  headerText,
  modelName,
  actionCtx,
  views,
  activeView,
  setActiveView,
  elements,

  showModelActions,
  defaultModelActionContext,
  defaultModelActionKind,
  exporters,
  isModelActionIconOnly,

  modelListQuery
}) => {
  const [result, graphKey, filterInstance, queryObj, selection] = modelListQuery
  const graphResult = result.graph?.[graphKey]
  return (
    <Flex sx={{
      alignItems: 'center',
      // mb: 2,
      mx: -2
    }}>
      <GraphTableHeaderPrimary
        sx={{
          mx: 2
        }}
        headerText={headerText}
        result={result}
        selection={selection}
        modelName={modelName}
        actionCtx={actionCtx}
        views={views}
        activeView={activeView}
        setActiveView={setActiveView}
        graphResult={graphResult}
      />

      <TableElements elements={elements?.primaryActions} />

      {showModelActions && (
        <Box mx={2}>
          <ActionGroup isModelActionIconOnly={isModelActionIconOnly} name={modelName} contexts={[defaultModelActionContext]} kinds={[defaultModelActionKind]} ctx={actionCtx?.model} />
        </Box>
      )}

      {exporters && (
        <Box mx={2}>
          <Exporters exporters={exporters} />
        </Box>
      )}

      <Box>
        <DownloadLink queryObj={queryObj} graphKey={graphKey} />
      </Box>

      <Pagination graph={graphResult} filterInstance={filterInstance} />
    </Flex>
  )
}

const GraphTableHeaderPrimary = ({
  sx,
  headerText,
  result,
  selection,
  modelName,
  actionCtx,
  views,
  setActiveView,
  activeView,
  graphResult
}) => {
  const { t } = useTranslation()
  const _headerText = headerText || t(`label.${changeCase.snake(modelName)}`)

  const isFetching = result?.status?.isFetching
  const refresh = result.refresh
  const hasMultipleAction = graphResult?.hasMultipleAction
  const activeGraphView = graphResult?.activeView
  const serverViews = graphResult?.views

  return (
    <Box sx={{
      flex: '1 1 auto',
      ...sx
    }}>
      <Flex alignItems='center' sx={{
      }}>
        <Card.Header sx={{
          fontSize: 'sm',
          mr: 2
        }}>
          {_headerText}
        </Card.Header>

        {isFetching ? (
          <Loader size={20} color='dark.500' />
        ) : (
          <Tooltip label='Yenile'>
            <Box as='a' sx={{
              display: 'block',
              ml: 2,
              color: 'dark.100',
              transition: 'all 0.2s ease',
              '&:hover': {
                color: 'brand.500',
              }
            }} onClick={refresh}>
              <Icon name='refresh' size={4} />
            </Box>
          </Tooltip>
        )}

        {hasMultipleAction && (
          <Flex alignItems='center'>
            <Checkbox mx={3} isChecked={selection.isActive} onChange={(e) => {
              if (e.target.checked) selection.actions.enableSelection()
              else selection.actions.clearSelectionGroup()
            }}>
              {selection.isActive && `${selection.count}`}
            </Checkbox>
            {selection.count > 0 && (
              <MultipleActionGroupMenu callbacks={{
                onSuccess: (data) => {
                  if (data?.deleted) selection.actions.clearSelectionGroup()
                }
              }} ids={selection.getIds()} name={modelName} ctx={{ ...actionCtx?.entity }} />
            )}
          </Flex>
        )}

        {(views || activeGraphView) && (
          <Box ml={5}>
            <GraphViews views={views} activeView={activeView} setActiveView={setActiveView} serverViews={serverViews} serverActiveView={activeGraphView} />
          </Box>
        )}
      </Flex>
    </Box>
  )
}

const GraphError = ({
  result
}) => {
  const { t } = useTranslation()
  if (result?.status?.status !== 'ERROR') return null

  return (
    <Alert>
      {result.status?.error?.kind === 'NOT_AUTHORIZED' ? t('text.unauthorized') : t('text.fetch_error')}

      <Button ml={3} size='sm' onClick={result.refresh} sx={{
        textTransform: 'capitalize',
      }}>{t('op.refresh')}</Button>

      {/* {result.status?.error?.kind !== 'NOT_AUTHORIZED' && <Button ml={3} size='sm' onClick={result.refresh} sx={{
        textTransform: 'capitalize',
      }}>{t('op.refresh')}</Button>} */}
    </Alert>
  )
}

const OutTableElements = ({
  elements,
  result,
  kind
}) => {
  if (!elements?.[kind]) return null
  return (
    <Box my={2}>
      {elements[kind]?.map((e, dx) => isFunction(e) ? e(result) : e)}
    </Box>
  )
}

const HeaderFilters = ({
  filters,
  filterInstance
}) => {
  if (!filters) return null
  return (
    <Flex sx={{
      mx: -3,
      my: 3
    }}>
      {filters.map((filter, dx) => {
        return (
          <Box sx={{ mx: 3 }} key={dx}>
            <FilterInput focus={dx === 0} label={filter.label} target={filter.target} kind={filter.kind} filterInstance={filterInstance} />
          </Box>
        )
      })}
    </Flex>
  )
}

const HeaderPrimaryFilters = ({
  graphResult,
  filterInstance
}) => {
  const { t } = useTranslation()
  if (!graphResult?.primaryFilters) return null

  return (
    <Flex sx={{
      mx: -3,
      my: 3,
      bg: 'light.200',
      px: 2,
      py: 2
    }}>
      {graphResult?.primaryFilters.map((filterGroup, dx) => {
        return (
          <Box sx={{
            mx: 3,
          }} key={dx}>
            <Box sx={{
              fontSize: 'sm',
              fontWeight: 'semibold',
              textTransform: 'capitalize'
            }}>
              {t(`label.${changeCase.snake(filterGroup.label || filterGroup.name)}`)}
            </Box>
            <Flex alignItems='center'>
              <Flex sx={{
                mx: -3,
              }}>
                {filterGroup.filters.map((filter, fdx) => {
                  const target = filterGroup.name != 'self' ? `${filterGroup.name}.${filter.name}` : filter.name
                  return (
                    <Box sx={{ mx: 3 }} key={fdx}>
                      <Flex sx={{
                        mx: -3,
                      }}>

                      </Flex>
                      <FilterInput focus={fdx === 0} label={filter.name} name={filter.name} target={target} kind={filter.inputType} filterInstance={filterInstance} componentName={filter.componentName} ctx={filter.ctx} />
                    </Box>
                  )
                })}
              </Flex>
            </Flex>
          </Box>
        )
      })}
    </Flex>
  )
}

const GraphTableFooter = ({
  graphResult,
  filterInstance,
  showIfCount = 20
}) => {
  if (graphResult?.count < showIfCount) return null
  return (
    <Flex sx={{
      alignItems: 'center',
      mb: 2,
      mx: -2,
      my: 4
    }}>
      <Box sx={{
        flex: '1 1 auto',
        mx: 2
      }}>

      </Box>

      <Pagination graph={graphResult} filterInstance={filterInstance} />
    </Flex>
  )
}

export default GraphTable