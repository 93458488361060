import React from 'react'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import * as RV from 'reactor-vera/templates/v1'


const ApiKeyManagement = ({
  filters
}) => {
  const result = RV.useReactorQuery({
    'rcApiKeyList': {
      itemFields: ['name', 'secretKind', 'isSetup'],
      filters
    }
  })
  return (
    <RV.QueryLoading result={result}>
      {({ rcApiKeyList }) => {
        return (
          <>
            {/* <RV.ReactorServerAction identifier='CmrBase.SetupGatewayConnect'/> */}
            <RV.SimpleTable
              header='API Anahtarları'
              maxHeight={0}
              items={rcApiKeyList.items}
              columns={[
                {
                  label: 'İsim',
                  key: 'name',
                },

                {
                  label: 'Değer',
                  key: '',
                  renderer: (item) => {
                    if (item.secretKind.value !== 'HASH_V1') return null
                    return (
                      <Box>
                        {item.isSetup ? '' : (
                          <Box>
                            <RV.ReactorServerAction identifier='RcApi.RevealApiKey' options={{ ctx: { id: item.id } }} elements={{
                              success: (result) => {
                                return result.data.apiKey
                              }
                            }} onClose={() => result.refresh()}/>
                          </Box>
                        )}
                      </Box>
                    )
                  }
                },

                {
                  label: 'Menü',
                  key: 'menu',
                  renderer: (item) => (
                    <Box>
                      <RV.ActionGroupMenu name={'RcApiKey'} contexts={['Entity']} ctx={{ id: item.id }} />
                    </Box>
                  )
                }
              ]}
            />
          </>
        )
      }}
    </RV.QueryLoading>
  )
}

export default ApiKeyManagement