import React from 'react'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import * as RV from 'reactor-vera/templates/v1'


const RnxContentProjects = props => {
  return (
    <>
      <RV.Routes>
        <RV.Route path='/' element={<RnxContentProjectHome />} />
        <RV.Route path='project/:rnxContentProjectId/*' element={<RnxContentProjectDetail />} />
      </RV.Routes>
    </>
  )
}

const RnxContentProjectHome = ({

}) => {
  return (
    <RV.GraphTable
      modelName='RnxContentProject'
      detailLink='project'
      query={{
        // filters: {
        //   for_owner_user: {}
        // },
        // itemFields: ['nfcContactProfile', 'color', 'photo', 'logo', 'name', 'profileName']
      }}
      displayAs={{
        mode: 'grid',
        cardRenderer: (item, dx) => <ContentProjectCard project={item} key={dx} />
      }}
    />
  )
}

const ContentProjectCard = ({
  project,
  link = true,
  withDetails,
  width = 200,
}) => {
  let borderWidth = 27 / 200 * width
  const imageHeight = 50 / 200 * width
  const logoSize = 60 / 200 * width
  const logoTop = '50'
  const color = project.color || 'brand.500'
  console.log('c', project)
  return (
    <RV.Card as={link ? RV.Link : undefined} to={`./project/${project.id}`} sx={{
      border: '1px solid #FFF',
      borderColor: 'light.500',
      borderRadius: 2,
      flex: `0 0 ${width}px`,
      width: `${width}px`,
      mx: 2,
      bg: project.photo ? '#FFF' : color,
      position: 'relative',
      display: 'block'
    }}>
      {project.photo ? (
        <Box sx={{
          position: 'relative',
          mx: -4,
          mt: -3,
          mb: 2,
        }}>
          <Box sx={{
            height: `${imageHeight}px`,
            overflow: 'hidden',
          }}>
            <Box as='img' src={project.photo.url} sx={{
              width: '100%',
              height: '100%',
              // display: 'block',
              objectFit: 'cover'
            }} />
          </Box>

          <Box sx={{
            transform: 'rotate(-7deg)',
            borderTop: `${borderWidth}px solid #FFF`,
            borderTopColor: color,
            position: 'absolute',
            bottom: '-9%',
            left: -5,
            width: `${width + 50}px`
          }} />
        </Box>
      ) : (
        <Box sx={{
          height: `${imageHeight}px`
        }} />
      )}

      <Box sx={{
        width: 0,
        height: 0,
        borderStyle: 'solid',
        borderWidth: `0 0 25px ${width}px`,
        borderColor: `transparent transparent #FFF transparent`,
        mx: -4
      }} />

      {project.logo && (
        <Box sx={{
          height: `${logoSize}px`,
          width: `${logoSize}px`,
          overflow: 'hidden',
          position: 'absolute',
          left: '10%',
          top: `${logoTop}%`,
          bg: color,
          border: '3px solid #FFF',
          borderRadius: 999999,
          textAlign: 'center'
        }}>
          <Box as='img' src={project.logo.url} sx={{
            width: '100%',
            height: '100%',
            // display: 'block',
            objectFit: 'contain'
          }} />
        </Box>
      )}

      <Box sx={{
        mb: -4,
        mt: -2,
        mx: -4,
        bg: '#FFF',
        px: 4,
        pt: 0,
        pb: 4
      }}>

        <Box sx={{
          textAlign: 'center',
          fontSize: 'lg',
          fontWeight: 'semibold',
          mt: 2
        }}>
          {project.name}
        </Box>

        <Box sx={{
          textAlign: 'center',
          fontSize: 'lg',
          fontWeight: 'semibold',
          mt: 2
        }}>
          <RV.Enum value={project.status} />
        </Box>

        {withDetails && (
          <Box mt={4}>
            <CardBoxDetails project={project} />
          </Box>
        )}
      </Box>
    </RV.Card>
  )
}

const iconMap = {
  'PHONE': 'mobile-phone',
  'EMAIL': 'envelope',
}

const CardBoxDetails = ({
  project
}) => {
  console.log('project', project)
  return (
    <>
      {project.nfcContactProfile?.contact_informations && (
        <Box>
          {project.nfcContactProfile?.contact_informations.map((i, dx) => {
            const iconName = iconMap[i.kind]
            let header = iconName ? <RV.Icon size={6} name={iconName} color='brand.500' sx={{
              mr: 2
            }} /> : null
            return (
              <Flex alignItems={'center'} sx={{
                my: 2
              }}>
                <Box>
                  {header}
                </Box>

                <Box>
                  {i.display_text || i.data}
                </Box>
              </Flex>
            )
          })}
        </Box>
      )}
    </>
  )
}

import ApiKeyManagement from 'elements/rc_api/rcApi/components/ApiKeyManagement'

const RnxContentProjectDetail = ({

}) => {
  return (
    <RV.EntityDetailPage
      modelName='RnxContentProject'
      displayMode='singleLine'
      routes={[
        entity => (
          <RV.Route path='/*' element={(
            <>
            <RV.RouteTabs
            tabs={[
              {
                name: 'Geliştirici',
                codeName: 'dev',
                element: (
                  <ApiKeyManagement filters={{owner: `RnxContentProject:${entity.id}`}}/>
                )
              }
            ]}
            />
            </>
          )} />
        )
      ]}
    />
  )
}

export default RnxContentProjects