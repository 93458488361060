import React from 'react'

// Reactor
import ReactorVeraApp from 'reactor-vera/ReactorVeraApp'

// Project
import storeCreator from './core/store'
import theme from './core/theme'

import rcAppApp from 'elements/rc_app/rcApp'
import rnxContent from 'elements/rnx_content/rnxContent'
import rcAddon from 'elements/rc_addon/rcAddon'

const apps = [
  rcAppApp,
  rnxContent,
  rcAddon
]


const App = () => (
  <ReactorVeraApp
    storeCreator={storeCreator}
    theme={theme}
    apps={apps}
    config={{
      channel: 'Reactor',
    }}
  />
)

export default App
