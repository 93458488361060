import React from 'react'

// ReactorUi
import { Flex, Box } from 'reactor-ui'

// Reactor
import { Routes, Route, Link, useParams, useNavigate } from 'reactor-vera/router'
import useBreadcrumb from 'reactor-vera/apps/ui/hooks/useBreadcrumb'


const Redirect = ({
  to
}) => {
  const navigate = useNavigate()
  React.useEffect(() => {
    navigate(to, { replace: true })
  }, [])
  return null
}

const RouteTabs = ({
  tabs,
  paramKey = 'tabId',
  ctx,
  forceIndexRoute = true,
  wrapperSx
}) => {
  const params = useParams()

  let redirectRoute = null
  if (forceIndexRoute) redirectRoute = <Route path='/' element={<Redirect to={`${tabs[0].codeName}`} />} />

  return (
    <Box sx={wrapperSx}>
      <Box sx={{
        mb: 3,
        position: 'relative'
      }}>
        <Flex mx={-2} sx={{

        }}>
          {tabs.map((tab, dx) => {
            // const isActive = dx === 0 && !forceIndexRoute ? params['*'] == '' : params['*'] === tab.codeName
            // console.log('--', params, tab.codeName)
            const isActive = (dx === 0 && params['*'] == '') || (params['*'] === tab.codeName || params['*'].split('/')[0] === tab.codeName)
            return (
              <Link to={dx === 0 && !forceIndexRoute ? './' : `./${tab.codeName}/`} key={dx} sx={{
                mx: 2,
                display: 'inlineBlock',
                // borderBottom: isActive ? '3px solid #FFF' : '1px solid #FFF',
                borderBottom: isActive ? '3px solid #FFF' : null,
                borderBottomColor: 'brand.500',
                textAlign: 'center',
                fontWeight: isActive ? 'bold' : 'normal',
                py: 2,
                px: 3,
                transition: 'all 0.1s linear',
                textTransform: 'capitalize',
                '&:hover': {
                  fontWeight: 'bold',
                  borderBottom: isActive ? '3px solid #FFF' : '1px solid #FFF',
                  borderBottomColor: 'brand.500',
                }
              }}>
                {tab.name}
              </Link>
            )
          })}
        </Flex>
        <Box sx={{
          borderBottom: '1px solid #FFF',
          borderBottomColor: 'brand.100',
          mt: '-2px'
        }}/>
      </Box>

      <Box>
        <Routes>
          <Route path='//*' element={<Tab tab={tabs[0]} ctx={ctx} />} />
          <Route path={`:${paramKey}/*`} element={<RouteTab tabs={tabs} ctx={ctx} paramKey={paramKey} />} />
          {redirectRoute}
        </Routes>
      </Box>
    </Box>
  )
}

const Tab = ({
  tab,
  ctx
}) => {
  const element = React.cloneElement(tab.element, { tab, ctx })
  return (
    element
  )
}

const RouteTab = ({
  tabs,
  ctx,
  paramKey
}) => {
  const params = useParams()
  const tabCodeName = params[paramKey]
  const tabIndex = tabs.findIndex(x => x.codeName === tabCodeName)
  const tab = tabs[tabIndex]
  const element = React.cloneElement(tab.element, { tab, ctx })

  if (tabIndex !== 0) return <BreadcrumbRouteTab element={element} tab={tab} />

  return (
    element
  )
}

const BreadcrumbRouteTab = ({
  element, tab
}) => {
  useBreadcrumb(`${tab.codeName}Tab`, tab.name)
  return element
}

export default RouteTabs