import RnxContentProjects from './pages/RnxContentProjects'

export default {
  name: 'RnxContent',
  components: {
    'RnxContentProjects': {
      component: RnxContentProjects
    },
  }
}
