import ReactorAddonManagement from './pages/ReactorAddonManagement'
import TenantAddonManagement from './pages/TenantAddonManagement'

export default {
  name: 'RcAddon',
  components: {
    'ReactorAddonManagement': {
      component: ReactorAddonManagement
    },
    'TenantAddonManagement': {
      component: TenantAddonManagement
    },
  }
}
