import React from 'react'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import * as RV from 'reactor-vera/templates/v1'
import ReactorModal from 'reactor-ui/components/Modal'


const TenantAddonManagement = props => {
  const { t } = RV.useTranslation()
  const result = RV.useReactorQuery({
    rcAddonGraph: {
      fields: [
        'systemTenantAddons',
        'installedSystemTenantAddons',
      ]
    }
  })

  return (
    <>
      <RV.Text.Header>
        {t('header.installed_addons')}
      </RV.Text.Header>

      <RV.QueryLoading result={result}>
        {({ rcAddonGraph }) => {
          return (
            <>
            {rcAddonGraph.installedSystemTenantAddons.length === 0 && (
              <RV.Alert status='info'>
                {t('text.addon.no_installed_addons')}
              </RV.Alert>
            )}
              <Flex mt={2} mx={-2}>
                {rcAddonGraph.installedSystemTenantAddons.map(addon => {
                  return (
                    <RV.Card sx={{ width: 300, mx: 2 }} key={addon.id}>
                      <RV.Card.HeaderArea>

                        <RV.Card.Header>
                          {addon.name}
                        </RV.Card.Header>

                        <ReactorModal
                          // size='full'
                          autoFocus={true}
                          header={`${addon.name}`}
                          anchor={<RV.Button icon='pencil' />}>
                          {({ onClose }) => (
                            // <RequestDataDetail entity={entity} />
                            <>
                              <InstalledAddonDetails addon={addon} onClose={onClose} refresh={result.refresh} />
                            </>
                          )}
                        </ReactorModal>

                      </RV.Card.HeaderArea>

                      <RV.Text.Info>
                        {t(`reactor.addon.${addon.name}.description`, addon.description)}
                      </RV.Text.Info>
                    </RV.Card>
                  )
                })}
              </Flex>
            </>
          )
        }}
      </RV.QueryLoading>

      <RV.Text.Header mt={4}>
      {t('header.addons')}
      </RV.Text.Header>

      <RV.QueryLoading result={result}>
        {({ rcAddonGraph }) => {
          return (
            <>
              <Flex mt={2} mx={-2}>
                {rcAddonGraph.systemTenantAddons.map(addon => {
                  return (
                    <RV.Card sx={{ width: 300, mx: 2 }} key={addon.key}>
                      <RV.Card.HeaderArea>

                        <RV.Card.Header>
                          {addon.name}
                        </RV.Card.Header>

                        <ReactorModal
                          // size='full'
                          autoFocus={true}
                          header={`${addon.name} Kurulum`}
                          anchor={<RV.Button icon='plus' />}>
                          {({ onClose }) => (
                            // <RequestDataDetail entity={entity} />
                            <>
                              <AddonInstall addon={addon} onClose={onClose} refresh={result.refresh} />
                            </>
                          )}
                        </ReactorModal>

                      </RV.Card.HeaderArea>

                      <RV.Text.Info>
                        {t(`reactor.addon.${addon.name}.description`, addon.description)}
                      </RV.Text.Info>
                    </RV.Card>
                  )
                })}
              </Flex>
            </>
          )
        }}
      </RV.QueryLoading>
    </>
  )
}

const AddonInstall = ({
  addon,
  refresh,
  onClose
}) => {
  const result = RV.useReactorQuery({
    rcSystemAddon: {
      fields: [
        'installFields'
      ],
      key: addon.key
    }
  })

  return (
    <RV.QueryLoading result={result}>
      {({ rcSystemAddon }) => {
        return (
          <>
            <RV.RfActionForm
              displayAs='FORM'
              onSuccess={() => {
                refresh()
                onClose()
              }}
              // icon='circle-remove'
              actionKey='RcAddon.InstallSystemAddon'
              fields={rcSystemAddon.installFields}
              initialValues={{
                key: addon.key,
              }}
            // buttonText='SİL'
            />
          </>
        )
      }}
    </RV.QueryLoading>
  )
}

const InstalledAddonDetails = ({
  addon,
  refresh,
  onClose
}) => {
  const result = RV.useReactorQuery({
    installedRcSystemAddon: {
      fields: [
        'services'
      ],
      id: addon.id
    }
  })

  return (
    <>
      <RV.QueryLoading result={result}>
        {({ installedRcSystemAddon }) => {
          console.log(installedRcSystemAddon)
          return (
            <>
              {installedRcSystemAddon.name}

              <RV.Text.Header>
                Servisler
              </RV.Text.Header>
              {installedRcSystemAddon.services.map(service => {
                return (
                  <Box key={service.name}>
                    <RV.Card.Header>
                      {service.name}
                    </RV.Card.Header>

                    {service.services.map(serv => {
                      return (
                        <Box key={serv.id}>
                          <RV.Layout.Row>
                            {serv.name}

                            <ReactorModal
                              // size='full'
                              autoFocus={true}
                              header={`${serv.name}`}
                              anchor={<RV.Button icon='pencil' />}>
                              {({ onClose }) => (
                                // <RequestDataDetail entity={entity} />
                                <>
                                  <InstalledServiceDetails service={serv} onClose={onClose} refresh={result.refresh} />
                                </>
                              )}
                            </ReactorModal>
                          </RV.Layout.Row>
                        </Box>
                      )
                    })}
                  </Box>
                )
              })}
            </>
          )
        }}
      </RV.QueryLoading>
    </>
  )
}

const InstalledServiceDetails = ({
  service,
  onClose,
  refresh
}) => {
  const result = RV.useReactorQuery({
    addonService: {
      fields: [
        'settingsFields',
        'values'
      ],
      id: service.id
    }
  })

  return (
    <RV.QueryLoading result={result}>
      {({ addonService }) => {
        console.log(addonService)
        return (
          <>
            <RV.RfActionForm
              displayAs='FORM'
              onSuccess={() => {
                refresh()
                onClose()
              }}
              // icon='circle-remove'
              actionKey='RcAddon.UpdateSystemAddonService'
              fields={addonService.settingsFields}
              initialValues={{
                id: service.id,
                settings: addonService.values
              }}
            // buttonText='SİL'
            />
          </>
        )
      }}
    </RV.QueryLoading>
  )
}

export default TenantAddonManagement