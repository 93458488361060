import React from 'react'

// Vendor
import { useTranslation } from 'react-i18next'
import { useField } from 'react-final-form'

// Reactor UI
import { Box, Flex } from 'reactor-ui'
import Button from 'reactor-ui/components/Button'
import Icon from 'reactor-ui/components/Icon'
import useFileDialog from 'reactor-ui/hooks/useFileDialog'

import validate from '../util/fieldValidator'
import RfFieldAlert from './RfFieldAlert'
import RfFieldDescription from './RfFieldDescription'


const RfFileField = ({
  name,
  field
}) => {
  const { t } = useTranslation()
  const { input, meta } = useField(name, {
    validate: (value) => validate(field, value),
  })
  const fileKinds = field.options?.acceptedKinds
  const isMultiple = field.isMultiple || field.options?.isMultiple
  const [f, setF] = React.useState(input.value)
  const currentFilesRef = React.useRef()
  currentFilesRef.current = input.value

  const openFileDialog = useFileDialog({
    acceptedFileKinds: fileKinds,
    onAdd: (files) => {
      if (isMultiple) {
        const val = currentFilesRef.current ? [...currentFilesRef.current] : []
        files && files.length && Array.from(files).forEach(f => {
          val.push(f)
        })
        input.onChange(val)
        field?.onChange?.(val)
      } else {
        if (files?.length) {
          input.onChange(files[0])
          field?.onChange?.(files[0])
        } else {
          input.onChange()
          field?.onChange?.()
        }
      }
    },
    multiple: isMultiple
  })

  return (
    <Box sx={{
      // flex: '1 1 0',
      // ml: 2
    }}>
      <Button isFullWidth onClick={openFileDialog} sx={{
        textTransform: 'capitalize'
      }}>
        <Icon name='cloud-upload'>
          {t(field.label)}
        </Icon>
      </Button>

      <Box mt={2}>
        {isMultiple ? (
          input?.value?.map?.((file, dx) => {
            return (
              <FileLine file={file} input={input} key={dx} field={field} />
            )
          })
        ) : (
          input?.value && <FileLine file={input.value} input={input} field={field} />
        )}
      </Box>
      <RfFieldAlert meta={meta} />
      <RfFieldDescription field={field} />
    </Box>
  )
}

const FileLine = ({
  file, input, field
}) => {
  return (
    <Flex sx={{
      my: 1,
      alignItems: 'center'
    }}>
      <Box sx={{ flex: '1 1 0' }}>
        <Icon name='file'>
          {file.name}
        </Icon>
      </Box>

      <Box sx={{
        ml: 2
      }}>
        <Button onClick={() => {
          const newVal = field.isMultiple ? input.value.filter(x => x !== file) : null
          input.onChange(newVal)
        }}>
          <Icon name='circle-remove' />
        </Button>
      </Box>
    </Flex>
  )
}

export default RfFileField