import React from 'react'

// Vendor
import { useTranslation } from 'react-i18next'
import i18n from 'i18next'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import Icon from 'reactor-ui/components/Icon'
import Button from 'reactor-ui/components/Button'
import Portal from 'reactor-ui/components/Portal'
import { Menu, MenuButton, MenuList, MenuItem } from 'reactor-ui/components/menu'
import useLocalStorage from 'reactor-ui/hooks/useLocalStorage'

import ReactorServerAction from 'reactor-vera/apps/data/components/ReactorServerAction'

// Reactor
import useResourceQuery from 'reactor/hooks/useResourceQuery'
import QueryLoading from 'reactor-vera/apps/ui/components/QueryLoading'
import * as changeCase from 'reactor-ui/util/text'
import { Link } from 'reactor-vera/router'


const UserProfile = props => {
  const data = useResourceQuery('rcApp.state.status')
  const rcUser = data?.rcUser
  const { t } = useTranslation()
  if (!rcUser) return null
  return (
    <Box sx={{
    }}>
      <Box sx={{
        mt: 4
      }}>
        <Icon name='user' size={10} color='brand.500'>
          {rcUser.name}
        </Icon>
      </Box>

      <Box mt={4} />

      <ReactorServerAction anchorProps={{ isFullWidth: false, my: 4 }} identifier='RcApp.SetRcUserOwnPassword'  options={{ ctx: { id: rcUser.id } }}/>

      <LanguageMenu />
    </Box>
  )
}

const LanguageMenu = ({

}) => {
  const languages = useResourceQuery('rcApp.user.languages')
  const [selectedLang, setSelectedLang] = useLocalStorage('reactor.opts.lang')
  const { t } = useTranslation()
  if (!languages) return null
  return (
    <Menu>
      <MenuButton as={Button} size='sm' colorScheme='brand' sx={{
        textTransform: 'capitalize'
      }}
      // rightIcon={<ChevronDownIcon />}
      >
        {t('text.languages')}
      </MenuButton>
      <Portal>
        <Box color='brand.500'>
          <MenuList>
            {languages.map(l => {
              return (
                <MenuItem key={l.key} onClick={() => {
                  i18n.changeLanguage(l.code)
                  setSelectedLang(l.code)
                  window.location.reload()
                }}>{t(`enum.${changeCase.snake(l.name)}`)}</MenuItem>
              )
            })}
          </MenuList>
        </Box>
      </Portal>
    </Menu>
  )
}

export default UserProfile